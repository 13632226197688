import { DoNotDisturb, Info, MoreVert } from "@mui/icons-material"
import PaymentIcon from "@mui/icons-material/Payment"
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  SxProps,
  Typography,
} from "@mui/material"
import { useHover } from "@uidotdev/usehooks"
import { t } from "i18next"
import { noop } from "lodash"
import moment from "moment"
import React, { FC, useEffect } from "react"
import { LockerDoorReservationSession } from "shared/lockerDoorReservationSession"
import { Reservation } from "shared/reservation"
import { useAppSelector } from "src/app/hooks"
import { selectHighlightedReservationId } from "src/features/things/thingsSlice"
import { useReservation } from "src/hooks/useReservation"
import { Locker } from "../../../shared/locker"
import { LockerCell, SstDoor } from "../../../shared/sst"
import FlashingDots from "./FlashingDots"

const menuOptions = ["removeReservationSession"]

type OsLockerDoorProps = {
  locker: Locker
  door: SstDoor
  reservation: Reservation
  tenantId: string
  onClick?: () => void
  isSelected?: boolean
  session?: LockerDoorReservationSession
  cell?: LockerCell
  onRemoveReservationSession?: (sessionId: string) => void
}

export const OsLockerDoor: FC<OsLockerDoorProps> = ({
  door,
  locker,
  reservation,
  tenantId,
  onClick = noop,
  isSelected = false,
  session,
  onRemoveReservationSession,
  cell,
}) => {
  const [ref, hovering] = useHover()

  const highlightedReservationId = useAppSelector(
    selectHighlightedReservationId,
  )

  useEffect(() => {
    if (highlightedReservationId === reservation?.id) {
      onClick()
    }
  }, [highlightedReservationId, reservation, reservation?.id, onClick])

  const hasDoorLink = !!door.links?.find((l) => l.rel === "reservation")

  const {
    expiresIn,
    expirationDate,
    hasExpired,
    showExpirationWarning,
    hasReservation,
    statusColor,
    statusTextColor,
    isReserving,
    hasPayment,
    hasDeposited,
    createdSince,
    isCompleted,
  } = useReservation({
    reservation,
    session,
    door,
    hasDoorLink,
  })

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleOptionClick = (option: string) => {
    if (option === "removeReservationSession") {
      onRemoveReservationSession?.(session?._id)
    }
    setAnchorEl(null)
  }

  const wrapperSx: SxProps = {
    // position: "relative",
    bgcolor: statusColor,
    color: statusTextColor,
    // p: 2,
    // width: 150,
    // height: 150,
    width: "100%",
    height: "100%",
    // grey shadow bottom right outside of box
    boxShadow: isSelected ? "3px 3px 8px 1px rgba(0,0,0,0.8)" : "none",

    "&:hover": isReserving
      ? { cursor: "not-allowed" }
      : {
          cursor: "pointer",
        },
  }
  if (door?.is_enable === false) {
    return (
      <Paper
        ref={ref}
        key={door.door_id}
        variant={"outlined"}
        elevation={isSelected ? 20 : 0}
        sx={{
          bgcolor: "lightgrey",
          borderRadius: 4,
          width: "100%",
          height: "100%",
          // vertical center
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack direction="column" spacing={1} alignItems="center">
          <DoNotDisturb
            sx={{
              fontSize: 40,
            }}
          />
          <Typography variant="body2">{t("unavailable")}</Typography>
        </Stack>
      </Paper>
    )
  }
  // TODO : WHEN LOADING A LOCKER PAGE: MUST TRIGGER  refresh locker to see which is in use
  if (
    (hasDoorLink && !reservation?.id) ||
    (!hasDoorLink && !door.is_available)
  ) {
    return (
      <Paper
        ref={ref}
        key={door.door_id}
        variant={"outlined"}
        elevation={isSelected ? 20 : 0}
        sx={{
          //very light fray
          bgcolor: "rgba(0,0,0,0.01)",
          borderRadius: 4,
          width: "100%",
          height: "100%",
          // vertical center
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack direction="column" spacing={1} alignItems="center">
          <Info
            sx={{
              fontSize: 40,
              color: "primary.main",
            }}
          />
          <Typography variant="body2">{t("busy")}</Typography>
        </Stack>
      </Paper>
    )
  }

  return (
    <Paper
      ref={ref}
      key={door.door_id}
      variant={isSelected ? "elevation" : "outlined"}
      elevation={isSelected ? 20 : 0}
      className={`uf-door ${
        (!hasReservation || isCompleted) && !isReserving
          ? "uf-door-available"
          : "uf-door-unavailable"
      }
      ${hasExpired ? "uf-door-expired" : ""}
      ${showExpirationWarning && !hasExpired ? "uf-door-expiring-soon" : ""}`}
      sx={{ ...wrapperSx, borderRadius: 4 }}
      onClick={isReserving ? noop : onClick}
    >
      <Stack
        direction="column"
        spacing={0}
        sx={{
          height: "100%",
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent={"space-between"}
          sx={{
            p: 1,
          }}
        >
          {!(hovering && cell?.door_size === "SMALL" && hasReservation) && (
            <Box
              sx={{
                borderRadius: "50%",
                width: 23,
                height: 23,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid ",
                borderColor: statusTextColor,
              }}
            >
              <Typography variant="caption">{door.door_label}</Typography>
            </Box>
          )}
          {hasPayment &&
            !isReserving &&
            !(hovering && cell?.door_size === "SMALL" && hasReservation) && (
              <Box>
                <PaymentIcon />
              </Box>
            )}
        </Stack>
        {isReserving && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVert
                sx={{
                  color: "grey",
                }}
              />
            </IconButton>
          </Box>
        )}
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {menuOptions.map((option) => (
            <MenuItem key={option} onClick={() => handleOptionClick(option)}>
              {t(option)}
            </MenuItem>
          ))}
        </Menu>
        {isReserving && (
          <Stack
            direction="column"
            spacing={cell?.door_size === "SMALL" ? 0 : 1}
            justifyContent={"center"}
            height="100%"
            sx={{
              px: 1,
            }}
          >
            <Typography
              fontSize="0.75rem;"
              variant="body1"
              fontWeight="bold"
              sx={{
                color: "primary.main",
              }}
            >
              {t("reservation")}
            </Typography>
            <FlashingDots />
            {session?.sessionStart && (
              <Typography variant="caption" color="primary.main">{`${t(
                "since",
              )} ${t(
                moment(session?.sessionStart).format("HH[h]mm"),
              )}`}</Typography>
            )}
          </Stack>
        )}
        {(!hasReservation || isCompleted) && !isReserving && (
          // available
          <Stack
            direction="column"
            pl={1}
            spacing={0}
            justifyContent={"center"}
            height="calc(100% - 30px)"
          >
            <Typography variant="body2" fontWeight="bold" color="whitesmoke">
              {t("available")}
            </Typography>
            <Box
              sx={{
                height: 0,
                width: "80%",
                maxWidth: 130,
                borderBottom: "2px solid rgba(255,255,255,0.5)",
              }}
            />
          </Stack>
        )}
        {!isReserving && hasReservation && !isCompleted && (
          <Stack
            direction="column"
            spacing={0}
            justifyContent={"center"}
            height="calc(100% - 30px)"
            sx={{
              px: 1,
              color: statusTextColor,
            }}
          >
            {cell?.door_size !== "SMALL" ||
            (cell?.door_size === "SMALL" && !hovering) ? (
              <Typography
                variant={cell?.door_size === "SMALL" ? "caption" : "body2"}
                fontWeight="bold"
              >
                {hasExpired
                  ? t("expired")
                  : !hasDeposited
                  ? t("waitingDeposit")
                  : t(
                      cell?.door_size === "SMALL"
                        ? "waitingPickupShort"
                        : "waitingPickup",
                    )}
              </Typography>
            ) : (
              <Box />
            )}
            <Box
              sx={{
                height: 0,
                width: "80%",
                maxWidth: 160,
                borderBottom: "2px solid",
                borderColor: statusTextColor,
              }}
            />
            {hasDeposited &&
            (cell?.door_size !== "SMALL" ||
              (cell?.door_size === "SMALL" && !hovering)) ? (
              <>
                {cell?.door_size !== "SMALL" && (
                  <Typography variant="caption">
                    {hasExpired ? t("since") : t("expire")}
                  </Typography>
                )}
                <Typography variant="h6">{expiresIn}</Typography>
              </>
            ) : (
              <>
                {cell?.door_size !== "SMALL" ||
                  (cell?.door_size === "SMALL" && hovering && !hasDeposited && (
                    <Typography variant="caption">{t("since")}</Typography>
                  ))}
                {!hasDeposited && (
                  <Typography variant="h6">{createdSince}</Typography>
                )}
              </>
            )}
            {hovering && hasDeposited && (
              <Stack direction="column" spacing={1}>
                <Typography variant="caption">{t("expiration")}</Typography>
                <Typography variant="caption">
                  {expirationDate
                    ? moment(expirationDate).format("DD/MM/YYYY HH[:]mm")
                    : "-"}
                </Typography>
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
    </Paper>
  )
}
